<template>
  <PenyertaanModalForm mode="Ubah" module="Daftar Penyertaan Modal Pada Perusahaan Afiliasi"> </PenyertaanModalForm>
</template>

<script>
import PenyertaanModalForm from './form';

const PenyertaanModalUpdate = {
  name: 'PenyertaanModalUpdate',
  components: { PenyertaanModalForm },
};

export default PenyertaanModalUpdate;
</script>
